import favicon from 'assets/images/favicon.ico'
import {graphql, useStaticQuery} from 'gatsby'
import React from 'react'
import {Helmet} from 'react-helmet'
import {MetaFragment} from 'types/graphtypes'

export const Meta: React.FC<{
	url: string
	meta: MetaFragment
}> = ({url, meta}) => {
	const {siteurl, project} = useStaticMetaQuery()
	const {title, description, imageFragment} = meta

	const src =
		imageFragment?.relationships?.field_media_image?.localFile?.publicURL
	const image = src && {
		src: src,
		width: imageFragment.field_media_image.width,
		height: imageFragment.field_media_image.height
	}

	return (
		<Helmet>
			<meta charSet="utf-8" />
			{process.env.GATSBY_GOOGLE_VERIFICATION && (
				<meta
					name="google-site-verification"
					content={process.env.GATSBY_GOOGLE_VERIFICATION}
				/>
			)}
			<link rel="icon" type="image/png" href={favicon} />
			<title>{`${title} | ${project}`}</title>
			<meta name="description" content={description} />
			<meta property="og:type" content="website" />
			<meta property="og:url" content={siteurl + url} />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description || ''} />
			<meta name="twitter:card" content="summary_large_image" />
			{image && <meta property="og:image" content={image?.src} />}
			{image && <meta property="og:image:width" content={image?.width} />}
			{image && <meta property="og:image:height" content={image?.height} />}
		</Helmet>
	)
}

export const fragment = graphql`
	fragment Meta on PageMeta {
		title
		description
		imageFragment: image {
			field_media_image {
				width
				height
			}
			relationships {
				field_media_image {
					localFile {
						publicURL
						name
					}
				}
			}
		}
	}
`

export const useStaticMetaQuery = (): {siteurl: string; project: string} => {
	const data = useStaticQuery(graphql`
		query MetaStatic {
			site {
				siteMetadata {
					siteUrl
					project
				}
			}
		}
	`)
	const siteMetadata = data.site.siteMetadata

	return {
		siteurl: siteMetadata?.siteUrl,
		project: siteMetadata?.project
	}
}
