import {BlockType} from 'blocks/block'
import {BlocksContext} from 'blocks/blockscontext'
import {jobFragmentToJobItem} from 'blocks/relatedjobs/relatedjobs_graph'
import {EditCMS} from 'layout/editcms'
import {Meta} from 'layout/meta'
import {Preview} from 'layout/pages/preview'
import {PingDrupal} from 'layout/pingdrupal'
import {previewFragmentToPreviewItem} from 'layout/preview/preview_graph'
import {PreviewType} from 'layout/preview/previewtype'
import * as langs from 'locale'
import React, {useEffect, useState} from 'react'
import {Helmet} from 'react-helmet'
import {PageContextType} from 'types/basictypes'
import {
	JobPreviewFragment,
	MetaFragment,
	PreviewFragment
} from 'types/graphtypes'
import unfetch from 'unfetch'
import {I18n} from 'util/i18'
import {PING_URL} from 'util/urls'
import {useUrlParams} from 'util/uselocation'

export type TemplateType = {
	pageContext: PageContextType
	lang: 'en' | 'nl'
	preview: PreviewType
	metaFragment: MetaFragment
	relatedFragments: Array<PreviewFragment>
	relatedJobsFragments: Array<JobPreviewFragment>
	content: Array<BlockType>
}

export const Template: React.FC<TemplateType> = ({children, ...attrs}) => {
	const {
		preview,
		pageContext,
		lang,
		relatedFragments,
		relatedJobsFragments,
		content,
		metaFragment
	} = attrs
	const {
		params: {devpreview}
	} = useUrlParams()
	useEffect(() => {
		const pingUrl = PING_URL(lang, preview.node_id)
		unfetch(pingUrl)
	}, [])

	if (devpreview) {
		return <Preview nodeID={preview.node_id} />
	}

	const url = pageContext.slug
	const relatedContent = relatedFragments.map(previewFragmentToPreviewItem)
	const relatedJobs = relatedJobsFragments.map(jobFragmentToJobItem)
	const langData = langs[lang]

	return (
		<BlocksContext.Provider
			value={{
				nodeID: preview.node_id,
				url,
				pageTitle: preview.title,
				pageType: preview.type,
				relatedContent,
				relatedJobs,
				parents: preview.parents,
				blocks: content
			}}
		>
			<I18n language={lang} data={langData}>
				<Meta url={url} meta={metaFragment} />
				<EditCMS type="node" id={preview.node_id} />
				<PingDrupal lang={lang} nodeID={preview.node_id} />
				<Helmet>
					<meta name="search" content={JSON.stringify(preview)}></meta>
				</Helmet>
				{children}
			</I18n>
		</BlocksContext.Provider>
	)
}
