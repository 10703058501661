import './editcms.scss'

import React, {useEffect} from 'react'
import fetch from 'unfetch'
import {PING_URL} from 'util/urls'

export const PingDrupal: React.FC<{
	lang: string,
	nodeID: string | number
}> = ({lang, nodeID}) => {

	useEffect(() => {
		const url = PING_URL(lang, nodeID)
		fetch(url)
	}, [])

	return <></>
}
